<template>
    <client-page>
        <sub-visual
        sh="회사소개"
        sc="에프원시큐리티가 여러분의 정보를 함께 보호하겠습니다."
        tabActive="인재채용"
        icon="/images/sub/visual/sv1-icon.svg"
        bg="/images/sub/visual/sv1.png"> 
        </sub-visual>

        <section class="sub-section pb-28 pb-md-28 pb-lg-54">
            <v-container>
                <div class="sub-tit-wrap">
                    <h3 class="sub-tit--lg mb-12 mb-lg-24" data-aos="fade-up">
                        <span class="color-point">F1 Security</span> 의 문은 <br class="d-none d-md-block">
                        항상 열려있습니다.
                    </h3>
                    <p class="page-text--lg color-gray">
                        보안전문가로서 F1 Security와 함께 발전 하고자 한다면 <br class="d-none d-md-block">
                        망설이지 말고 문을 두드리시길 바랍니다.
                    </p>
                </div>
            </v-container>
        </section>

        <section class="recruitment-bg py-26 pb-lg-66">
            <v-container>
                <v-img class="d-block mx-auto" max-width="624" src="/images/sub/about/recruitment/recruitment-img.png"></v-img>
            </v-container>
        </section>

        <section class="sub-section-sm sub-section-sm--first">
            <v-container>
                <h4 class="sub-tit--md line-height-1 mb-20 mb-lg-30" data-aos="fade-up">채용절차</h4>
                <v-img class="d-none d-md-block mx-auto" max-width="1107" src="/images/sub/about/recruitment/recruitment-img2.png"></v-img>
                <v-img class="d-block d-md-none mx-auto" max-width="500" src="/images/sub/about/recruitment/recruitment-img2-mo.png"></v-img>
            </v-container>
        </section>

        <section class="sub-section-sm">
            <v-container>
                <h4 class="sub-tit--md line-height-1 mb-20 mb-lg-30" data-aos="fade-up">근무환경</h4>
                <div class="bg-white recruitment-slide-wrap">
                    <div class="recruitment-slide">
                        <div class="swiper-container">
                            <div class="swiper-wrapper">
                                <div class="swiper-slide">
                                    <img src="/images/sub/about/recruitment/slide-img1.png" alt="" class="d-block w-100"> 
                                </div>
                                <div class="swiper-slide">
                                    <img src="/images/sub/about/recruitment/slide-img2.png" alt="" class="d-block w-100"> 
                                </div>
                                <div class="swiper-slide">
                                    <img src="/images/sub/about/recruitment/slide-img3.png" alt="" class="d-block w-100"> 
                                </div>
                                <div class="swiper-slide">
                                    <img src="/images/sub/about/recruitment/slide-img4.png" alt="" class="d-block w-100"> 
                                </div>
                                <div class="swiper-slide">
                                    <img src="/images/sub/about/recruitment/slide-img5.png" alt="" class="d-block w-100"> 
                                </div>
                                <div class="swiper-slide">
                                    <img src="/images/sub/about/recruitment/slide-img6.png" alt="" class="d-block w-100"> 
                                </div>
                            </div>
                        </div>
                        <div class="recruitment-btn recruitment-prev"><i class="icon icon-arrow-left-sm"></i></div>
                        <div class="recruitment-btn recruitment-next"><i class="icon icon-arrow-right-sm"></i></div>
                        <div class="recruitment-pagination"></div>
                    </div>
                    <p class="page-text font-weight-bold text-center pa-18 border">
                        스낵바, 휴게실, 회의실, 카페테리아, 노트북, 사무용품 지급
                    </p>
                </div>
            </v-container>
        </section>

        <section class="sub-section-sm sub-section--last">
            <v-container>
                <h4 class="sub-tit--md line-height-1 mb-20 mb-lg-30" data-aos="fade-up">복리후생</h4>

                <v-row class="my-lg-n10">
                    <v-col cols="12" sm="6" md="4" class="py-lg-10">
                        <div class="num-wrap pt-18 pt-lg-22 h-100" data-aos="fade-right" data-aos-delay="100">
                            <span class="num page-text">01</span>
                            <div class="h-100 radius-sm border pa-16 pt-34 pt-md-58 pb-md-50 d-flex flex-column align-center justify-center text-center">
                                <img src="/images/sub/about/recruitment/recruitment-icon1.png" alt="" class="d-block mb-12 mb-lg-24">
                                <h5 class="sub-tit--sm line-height-1 font-weight-bold mb- mb-8 mb-lg-20">
                                    리프레시
                                </h5>
                                <p class="page-text color-gray">
                                    특별휴가, 근로자휴가 지원사업, <br class="d-none d-md-block">
                                    산전 후 휴가, 육아휴직, 남성출산휴가
                                </p>
                            </div>
                        </div>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" class="py-lg-10" data-aos="fade-right" data-aos-delay="400">
                        <div class="num-wrap pt-18 pt-lg-22 h-100">
                            <span class="num page-text">02</span>
                            <div class="h-100 radius-sm border pa-16 pt-34 pt-md-58 pb-md-50 d-flex flex-column align-center justify-center text-center">
                                <img src="/images/sub/about/recruitment/recruitment-icon2.png" alt="" class="d-block mb-12 mb-lg-24">
                                <h5 class="sub-tit--sm line-height-1 font-weight-bold mb- mb-8 mb-lg-20">
                                    지원금 · 보험
                                </h5>
                                <p class="page-text color-gray">
                                    본인학자금, 도서 구입 및 자격증 취득 <br class="d-none d-md-block">
                                    내일채움공제, 건강검진, 각종 경조사 지원
                                </p>
                            </div>
                        </div>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" class="py-lg-10" data-aos="fade-right" data-aos-delay="700">
                        <div class="num-wrap pt-18 pt-lg-22 h-100">
                            <span class="num page-text">03</span>
                            <div class="h-100 radius-sm border pa-16 pt-34 pt-md-58 pb-md-50 d-flex flex-column align-center justify-center text-center">
                                <img src="/images/sub/about/recruitment/recruitment-icon3.png" alt="" class="d-block mb-12 mb-lg-24">
                                <h5 class="sub-tit--sm line-height-1 font-weight-bold mb- mb-8 mb-lg-20">
                                    교육 · 생활
                                </h5>
                                <p class="page-text color-gray">
                                    우수사원시상식, 신입사원교육(OJT), 워크샵, <br class="d-none d-md-block">
                                    직무능력향상교육, 가족친화 우수기업
                                </p>
                            </div>
                        </div>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" class="py-lg-10" data-aos="fade-right" data-aos-delay="1000">
                        <div class="num-wrap pt-18 pt-lg-22 h-100">
                            <span class="num page-text">04</span>
                            <div class="h-100 radius-sm border pa-16 pt-34 pt-md-58 pb-md-50 d-flex flex-column align-center justify-center text-center">
                                <img src="/images/sub/about/recruitment/recruitment-icon4.png" alt="" class="d-block mb-12 mb-lg-24">
                                <h5 class="sub-tit--sm line-height-1 font-weight-bold mb- mb-8 mb-lg-20">
                                    조직문화
                                </h5>
                                <p class="page-text color-gray">
                                    회식강요 안함, 야근강요 안함, 자유복장, <br class="d-none d-md-block">
                                    자유로운 연차 사용
                                </p>
                            </div>
                        </div>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" class="py-lg-10" data-aos="fade-right" data-aos-delay="1300">
                        <div class="num-wrap pt-18 pt-lg-22 h-100">
                            <span class="num page-text">05</span>
                            <div class="h-100 radius-sm border pa-16 pt-34 pt-md-58 pb-md-50 d-flex flex-column align-center justify-center text-center">
                                <img src="/images/sub/about/recruitment/recruitment-icon5.png" alt="" class="d-block mb-12 mb-lg-24">
                                <h5 class="sub-tit--sm line-height-1 font-weight-bold mb- mb-8 mb-lg-20">
                                    선물
                                </h5>
                                <p class="page-text color-gray">
                                    명절선물/귀향비, <br class="d-none d-md-block">
                                    생일선물/파티
                                </p>
                            </div>
                        </div>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" class="py-lg-10" data-aos="fade-right" data-aos-delay="1600">
                        <div class="num-wrap pt-18 pt-lg-22 h-100">
                            <span class="num page-text">06</span>
                            <div class="h-100 radius-sm border pa-16 pt-34 pt-md-58 pb-md-50 d-flex flex-column align-center justify-center text-center">
                                <img src="/images/sub/about/recruitment/recruitment-icon6.png" alt="" class="d-block mb-12 mb-lg-24">
                                <h5 class="sub-tit--sm line-height-1 font-weight-bold mb- mb-8 mb-lg-20">
                                    급여제도
                                </h5>
                                <p class="page-text color-gray">
                                    우수사원포상, 장기근속자 포상제도, <br class="d-none d-md-block">
                                    퇴직금, 4대 보험
                                </p>
                            </div>
                        </div>
                    </v-col>
                    
                </v-row>
            </v-container>
        </section>

    </client-page>
</template>

<script>
import Swiper from "swiper/bundle";
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
export default {
    components: {
        ClientPage,
        SubVisual,
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            this.$nextTick(() => {
                this.swiper = new Swiper(".recruitment-slide .swiper-container", {
                    effect: "fade",
                    allowTouchMove: true,
                    centeredSlides: true,
                    spaceBetween: 0,
                    autoplay: {
                        delay: 5000,
                        disableOnInteraction: false,
                    },
                    speed: 500,
                    loop: true,
                    pagination: {
                        el: '.recruitment-pagination',
                        clickable: true,
                    },
                    navigation: {
                        nextEl: ".recruitment-next",
                        prevEl: ".recruitment-prev",
                    },
                });
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.recruitment-bg{
    background-image: url(/images/sub/about/recruitment/recruitment-bg.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.recruitment-slide{
    position: relative;
}

.recruitment-slide .swiper-container{
    border-radius: var(--radius-md) var(--radius-md) 0 0;
    border-top: 1px solid var(--border-color);
    border-left: 1px solid var(--border-color);
    border-right: 1px solid var(--border-color);
    overflow: hidden;
    width: 100%;
}

.recruitment-slide-wrap>p{
    border-radius: 0 0 var(--radius-md) var(--radius-md);
    overflow: hidden;
}

.recruitment-pagination{
    display: flex;
    position: absolute;
    left: 50%;
    bottom: 16px;
    transform: translate(-50%, 0);
    z-index: 5;
}

.recruitment-btn{
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: #fff;
    border: 1px solid var(--border-color);
    display: none;
    align-items: center;
    justify-content: center;
    z-index: 5;
}

.recruitment-prev{
    left: 16px;
}

.recruitment-next{
    right: 16px;
}

@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
    .recruitment-btn{
        display: flex;
    }
    .recruitment-pagination{
        bottom: 30px;
    }
}
@media (min-width:1200px){
    .recruitment-prev{
    left: -24px;
    }

    .recruitment-next{
        right: -24px;
    }
}

.num-wrap{
    position: relative;
}

.num{
    width: 37px;
    height: 37px;
    border-radius: 50%;
    background-color: #3d5063;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #fff;
    line-height: 1;
}

@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
    .num{
        width: 45px;
        height: 45px;
    }
}
@media (min-width:1200px){
}


</style>